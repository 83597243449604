//******************************************************************************
  * Check password strength
  ******************************************************************************
.password-info
  margin: 3px 0 0 0
  padding: 0
  &__title, &__body
    +dib
  &__title
    font:
      size: $font-size-base - 2
      weight: normal
    &::after
      content: ':'
  // colors
  .password-short,
  .password-bad
    color: $brand-danger
  .password-weak
    color: #E66C2C
  .password-good
    color: #2D98F3
  .password-strong
    color: #006400
