//******************************************************************************
  * Social accounts
  * Currently FB & TW
  ******************************************************************************
.social-accounts
  flex-grow: 0

  //&__buttons and &__separator needed for signup

  &__buttons
    +fr
  &__separator
    +fl
    height: calc(100% - #{$grid-gutter-width})
    +por
    +tac
    padding: 0 $grid-gutter-width 0 $grid-gutter-width
    &::before, &::after
      border-left: 1px solid $gray-icon
      content: ''
      +db
      +h(35%)
      right: 50%
      top: 2px
      +poa
      +w(0)
    &::after
      top: auto
      bottom: 0
    div
      color: $gray-icon
      transform: translateY(-50%)
      top: 50%
      +por
  .btn
    background-color: rgba(0,0,0,0.1)
    color: $black
    border: 0
    .ico
      position: relative
      top: -1px
    &:hover, &:focus, &:active
        color: $white
        border-color: transparent
  .btn-fb
    &:hover, &:focus, &:active
      background-color: $facebook-color
  .btn-tw
    &:hover, &:focus, &:active
      background-color: $twitter-color
  .btn-gg
    &:hover, &:focus, &:active
      background-color: $google-color
  .btn-bio
    &:hover, &:focus, &:active
      background-color: $brand-dark
  .btn-ap
    &:hover, &:focus, &:active
      background-color: $apple-color