.removal-type
  width: 100%
  margin-bottom: 20px

  .checkbox
    margin: 0

  td
    padding: 20px 10px
  label
    padding: 0 5px
    font-weight: normal
    position: relative
    top: -2px

  p
    margin: 0
    padding: 0 22px
    font-weight: bold
