//******************************************************************************
  * Two step verifications pages
  ******************************************************************************
.page_two-steps
  .text-indent
    display: table
    p
      display: table-row
      strong
        min-width: 45px
        display: table-cell
        table-layout: fixed
      span
        width: 100%
        display: table-cell
      .hyphen
        width: 3%
        text-align: center

  h3
    font:
      size: $font-size-base + 1
      weight: normal
    margin-bottom: 2 * $grid-min-size

  .btn-toolbar
    clear: both

.qr-code
  &__img
    +w(228px)

.card
  &_app-group
    +bgc(transparent)
    border: none
    +box-shadow(none)
    &:not(:last-child)
      margin-bottom: $grid-min-size

    .card-title
      font:
        size: $font-size-base + 1
        weight: 700
      +lh(2)

    .list-unstyled
      li
        +lh(1.8)

    [class^="ifi-"]
      visibility: hidden

    a:hover
      [class^="ifi-"]
        visibility: visible

.secret-key
  +dib
  +lh(1.8)
  margin: 3px 0
  padding: $grid-min-size (2 * $grid-min-size)
  +tac
