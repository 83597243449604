//******************************************************************************
  * Description:  Variables used in the sass files.
  ******************************************************************************
// Compass
$default-border-radius: $border-radius-base

$btn-height:          4 * $grid-min-size - 2
$btn-default-height:  4 * $grid-min-size + 2
$btn-sm-height:       4 * $grid-min-size

$btn-disabled-bg:     #f1f1f1
$btn-disabled-color:  lighten($btn-default-color, 25%)
$btn-disabled-border:  lighten($btn-disabled-color, 35%)

$border-radius-large: $border-radius-base
$btn-border-radius-large: $border-radius-base
$input-border-radius-large: $border-radius-base

$aside-width:      32 * $grid-min-size
$footer-height:    17 * $grid-min-size
$input-min-width:  175px
$captcha-height:   120px
$captcha-width:    270px

// Bootstrap
$grid-gutter-width: 14px
$title-bar-height:  4 * $grid-min-size

// Profile picture dimensions
$prof-pic-height-base: 107px
$prof-pic-width-base: 107px

$apple-color: $black

