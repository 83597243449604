#activity_table
  .ifi-check
    color: $brand-success
  td
    vertical-align: middle
    &.td-icon
      text-align: center
  .icon
    fill: #000
    width: auto
    height: 32px
    padding-right: 10px
