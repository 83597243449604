.customized-buttons
  .btn.google_branding
    background: url("../../img/sync/google_signin.png") no-repeat
    font-size: 0
    width: 191px
    height: 46px

#pageMailSync
  .text-secondary
    color: $gray-800 !important
