//******************************************************************************
  * Block: Profile pic on "Profile overview" and "Personal data" pages
  ******************************************************************************
$img-thumb-margin: 2px

.profile-data-pane
  +por
  &_personal
    .profile-img
      bottom: 0
      top: auto
  .form-control-static
    +oh
    text-overflow: ellipsis
    white-space: nowrap
    +w(100%)

.profile-data
  +fl
  margin-right: -#{$prof-pic-width-base + $grid-gutter-width}
  +w(100%)

  &__wrap
    margin-right: $prof-pic-width-base + $grid-gutter-width

.profile-img
  +poa
  right: 0
  top: 0
  .form-label
    padding-bottom: 5px

.inx-img-crop
  &_profile
    border: 1px solid $gray-lighter
    border-radius: 0 // $border-radius-base
    +h($prof-pic-height-base)
    +oh
    +por
    +w($prof-pic-width-base)

    .inx-profile__thumb
      border-radius: 0

    .ifi-profile
      +fsz($prof-pic-height-base)
      +lh($prof-pic-height-base - 25px)
