// Customize
.panel_signup, .panel_set-new-pass
  .input-group-text
    background-color: $input-bg
  .input-group
    +fade-icon($startOpacity: 0.2)

// Regenerate pass on "Reset pass" page
.input-group_custom
  .btn_customized
    +h($input-height-base)

// Caps Lock is on icon fix
.login-form
  .form-control[type="password"]
    z-index: initial
