.captcha-page
  padding: $grid-min-size 0
  .form-inline
    margin: 0 auto
    +w(270px)
  .form-group
    clear: both
    +db
    padding: (2 * $grid-min-size) 0
  .btn-default
    background-color: #ddd
    border-color: #c1c1c1
    &:hover
      background-color: #aaa
      border-color: #999
      color: white
  .captcha__regenerate
    background: 0
    border: 0
    color: #666
    cursor: pointer
    +fsz($font-size-base)
    margin: $grid-min-size 0
    padding: 0 $grid-min-size
  .captcha__input
    +w(13 * $grid-min-size !important)
.headline
  +db
  +h($title-bar-height)
  +lh($title-bar-height)
  margin: 0 0 ($grid-min-size - 1) 0
  padding: 0 $grid-min-size
  +oh
  +por
  +border-top-radius($border-radius-base)
  +w(100%)

  &_with-description
    border-bottom:
      style: solid
      width: 1px
    +h
    margin-bottom: $grid-min-size

  &__title
    +db
    font:
      size: $font-size-base + 5
      weight: normal
    +lh(inherit)
    +por
    // Mail read page
    .headline_eml-read &
      float: none
      +w

  &__description
    +fsz($font-size-base - 1)
    +lh(1.2)
    margin: 0
    padding: 0 0 $grid-min-size 0

#recaptchaWrap
  width: 302px