//******************************************************************************
  * Dashboard styles (user profile index page)
  ******************************************************************************
.page_dashboard
  .fset__content
    +por
  .secret-question-body
    min-height: 256px

.pset__title
  font-weight: bold
  padding-top: $grid-min-size
  &:first-child
    padding-top: 0

.pset__content
  +h(5 * $grid-min-size + 2, min)
  padding-top: $grid-min-size
  +oh
  text-overflow: ellipsis
  white-space: nowrap
  +w(100%)
  &:first-child
    margin-top: 0
