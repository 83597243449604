//******************************************************************************
  * Form styles
  ******************************************************************************

fieldset
  border: 0
  +nmp
.radio, .checkbox
  // Reposition radios (We have smaller font-size and line-height)
  label
    +lh(1.7)
    min-height: 3 * $grid-min-size

//this was in bs3 but removed in bs5
.help-block
  color: #737373
  font-size: 0.6rem

//profile menu has blue links but other list group items should be darker
.list-group-item
  > label
    color: $gray-dark
  > span
    color: $gray-dark
