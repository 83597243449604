//******************************************************************************
//* Customized bootstrap variables here
//******************************************************************************
//** Background color for `<body>`.
$body-bg:               #fff;
//** Global text color on `<body>`.
$text-color:            #333;
// Global textual link color.
$link-color:            #2a6887;
// Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%);
// Link hover decoration.
//$link-hover-decoration: none;

//$grid-gutter-width: 14px;
// $input-height-base: 4 * $grid-min-size + 2;

//== Border radius
$border-radius-small: 3px;

// === Buttons
// Default
// $btn-font-weight:                normal !default;

$btn-default-color:             $text-color;
$btn-default-bg:                $gray-lighter;
$btn-default-border:            #ccc;

$btn-primary-color:             $white;
$btn-primary-bg:                $brand-dark;
$btn-primary-border:            $brand-dark;

// $btn-success-color:              #fff !default;
// $btn-success-bg:                 $brand-success !default;
// $btn-success-border:             darken($btn-success-bg, 5%) !default;

// $btn-info-color:                 #fff !default;
// $btn-info-bg:                    $brand-info !default;
// $btn-info-border:                darken($btn-info-bg, 5%) !default;

 $btn-warning-color:              #fff !default;
 $btn-warning-bg:                 $brand-warning !default;
 $btn-warning-border:             darken($btn-warning-bg, 5%) !default;

$btn-danger-color:               #fff;
$btn-danger-bg:                  $brand-orange;
$btn-danger-border:              darken($btn-danger-bg, 5%);

//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding:               floor(calc($grid-gutter-width / 2));
$alert-border-radius:         $border-radius-small;
// $alert-link-font-weight:      bold !default;

$alert-success-bg:            $state-success-bg !default;
$alert-success-text:          $state-success-text !default;
$alert-success-border:        $state-success-border !default;

$alert-info-bg:               $state-info-bg !default;
$alert-info-text:             $state-info-text !default;
$alert-info-border:           $state-info-border !default;

$alert-warning-bg:            $state-warning-bg !default;
$alert-warning-text:          $state-warning-text !default;
$alert-warning-border:        $state-warning-border !default;

$alert-danger-bg:             $brand-danger;
$alert-danger-text:           $white;
$alert-danger-border:         $state-danger-border !default;


// === List group
$list-group-link-color:    $link-color;
$list-group-active-color:  $link-color;
$btn-border-radius-base:   $btn-border-radius
//$accordion-button-bg: $gray-lighter !default;
//$accordion-button-active-bg: $btn-default-bg !default;
