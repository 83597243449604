:root {
  --bs-body-font-size: .875rem;

  --bs-body-bg: #fff;
  //--bs-border-width: 1px;
  --bs-border-radius: 0.125rem;
  --bs-btn-border-radius: 0.125rem;
  --bs-border-radius-lg: 0.5rem;

  --bs-primary-rgb: #{red($brand-dark)},#{green($brand-dark)},#{blue($brand-dark)}; // converted from $brand-dark

  --bs-success-text-emphasis: #3c763d;
  --bs-success-bg-subtle: #dff0d8;
  --bs-success-border-subtle:  darken(adjust-hue(--bs-success-bg-subtle, -10), 5%);

  --bs-info-text-emphasis: #31708f;
  --bs-info-bg-subtle: #d9edf7;
  --bs-info-border-subtle:  darken(adjust-hue(--bs-info-bg-subtle, -10), 7%);

  --bs-warning-text-emphasis: #7c653a;
  --bs-warning-border-subtle:  #ffe8bc;
  --bs-warning-bg-subtle: #fff8d5;

  --bs-danger-text-emphasis: #a94442;
  --bs-danger-bg-subtle: #f2dede;
  --bs-danger-border-subtle:  darken(adjust-hue(--bs-danger-bg-subtle, -10), 5%);
}

.btn.btn-secondary {
  --bs-btn-border-color: #ccc;
}
