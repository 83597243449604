//******************************************************************************
  * Misc styles
  ******************************************************************************
// Override bstrap setting
small
  font-size: 0.75rem

// Margin classes
.mb-0
  margin-bottom: 0
.mb-xs
  margin-bottom: calc($grid-gutter-width / 2)
.mb-sm
  margin-bottom: $grid-gutter-width
.mb-md
  margin-bottom: 2 * $grid-gutter-width

.mt-0
  margin-top: 0
.mt-md
  margin-top: 2 * $grid-gutter-width
.mt-sm
  margin-top: $grid-gutter-width
.mt-xs
  margin-top: calc($grid-gutter-width / 2)

// Horizontal rule
.ci-hr
  border:
    bottom: 1px solid #fff
    top: 1px solid #dadada
  margin: calc($grid-gutter-width / 2) 0
  hr
    +dn
  &_s1
    margin: $grid-gutter-width 0
  &_ntm
    margin-top: 0

article
  .text-danger
    color: $brand-danger !important

// Required fields style
.required
  &:before
    content: ''
    background-color: $brand-danger
    +dib
    +h($grid-min-size - 1)
    margin: 0 4px 2px 2px
    vertical-align: baseline
    +w($grid-min-size - 1)
    border-radius: 50%

#menus-profile .list-group-item
  .required
    margin: 1px 0
    position: absolute
    right: 10px

// Tooltip
body .ii-tooltip
  &__pane
    background-color: #f5fbfb

.page-iframe
  margin-top: -7px

.body-iframe
  height: auto

// Inbox footer
.inx-footer
  padding-top: 0

.tp-icon
  display: inline-block
  vertical-align: bottom

.height-holder
  display: block

// Hide login button when reading of the Privacy Policy is forced
.special-page
  .nav-login
    display: none !important
// Special request from marketing [LOGIN-2533]
#menus-profile
  .back-link
    color: $brand-red

// Nofications page
.notifications-list
  .checkbox
    &:first-child
      margin-top: 0
    &:last-child
      margin-bottom: 0

input[type=number]
  // Firefox
  -moz-appearance: textfield

// hide html5 number input spinbox
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none
  margin: 0 /* <-- Apparently some margin are still there even though it's hidden */

.line-height-md
  line-height: 3rem

// display classes
.d-block
  display: block
