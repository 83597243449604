.recovery-email
  margin: 0
  font-size: 16px
  line-height: 36px

  .text-primary
    max-width: calc(100% - 145px)
    overflow: hidden
    text-overflow: ellipsis
    display: inline-block
  .text-danger
    position: relative
    top: -12px
