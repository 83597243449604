// FIXME
@media (min-width: 720px)
  .modal-dialog.modal-sm
    +w(26vw, min)

@media (max-width: 719px)
  select
    &.select_b-day, &.select_b-month
      margin-bottom: 1rem

//todo move to toolbarbundle as all have max-width 1320px instead of default 1920px also for very large screen
@media all and (min-width: 1920px)
  .container
    max-width: 1320px !important
