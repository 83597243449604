//******************************************************************************
  * Block: captcha
  ******************************************************************************
.captcha
  // +h
  margin: 0 auto
  +w($captcha-width + 2)
  +fade-icon

  &__content
    +db
    background:
      color: #f8f8f8
      position: center 45%
    border: 1px solid #ddd
    border-radius: $border-radius-base
    +h
    margin-bottom: $grid-gutter-width
    +tar
    +w

  &__img
    +db
    +h($captcha-height)
    +w($captcha-width)
    border-top-left-radius: $border-radius-base
    border-top-right-radius: $border-radius-base

  &__regenerate
    background: transparent
    border: 0
    color: $link-color
    +lh($btn-height)
    padding-right: $grid-min-size
    &:hover
      color: $link-hover-color

  &__toolbar
    +lh($btn-height)
    +clearfix

  &__hint
    color: $gray-light
    +fl
    +fsz(1.1rem)
    left: 4px
    +por
    top: 3px

  .form-control
    letter-spacing: 2px
    +fsz(1.1rem)
    padding:
      bottom: 0
      top: 0
    +tac

// Captcha in modal
.ui-dialog
  .captcha
    margin-bottom: $grid-gutter-width
    .text-danger
      margin-top: 0
      padding-top: 0

    &__regenerate
      +lh($btn-height)

// Recaptcha tweaks
#recaptchaContent
  width: auto
  &.captcha
    > div:first-child
      margin: 0 auto

// Recaptcha for mobiles (iPhone)
@media screen and (max-height: 575px), screen and (max-width: 992px)
  #recaptchaContent
    min-height: 80px
    position: relative
    &.captcha
      > div:first-child
        left: 50%
        position: absolute
        top: 50%
        transform: translate(-50%, -50%) scale(0.80)

// Hide recaptcha audio button
#recaptcha-audio-button
  display: none
