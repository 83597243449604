// Module: Image cropper styles

$img-thumb-margin: 2px

%elm
  +db
  +poa
  +h(100%)
  left: 0
  top: 0
  +w(100%)

%hover
  &:hover
    .ifi
      opacity: 1
    .btn
      &_img-add, &_img-edit
        background-color: rgba($gray-lighter, 0.75)

%cnt
  .img__upload
    cursor: pointer
    opacity: 0
    @extend %elm
  .ifi-profile
    color: $gray-lighter
  .ifi-plus, .ifi-pencil
    color: rgba($gray-light, 0.9)
    +fsz($font-size-h1)
    opacity: 0
    transition: opacity ease 0.5s
  .btn
    &_img-add, &_img-edit
      +bgc(transparent)
      +nmp
      @extend %elm
      &:focus
        outline: none

.inx-img-crop
  +oh
  +por
  @extend %cnt
  @extend %hover

  .img-thumbnail
    background:
      repeat: no-repeat
      position: center
      size: 100%
    border: 0
    +db
    +h(calc(100% - #{2 * $img-thumb-margin}))
    margin: $img-thumb-margin
    +w(calc(100% - #{2 * $img-thumb-margin}))

  .ifi-profile
    top: -1px

  .ifi-plus
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    width: 100%
    height: 100%
    margin: auto
    background-color: rgba(238, 238, 238, 0.9)
    text-align: center
    line-height: 105px
    text-decoration: none
    border: 0

.inx-img-crop-dialog
  @extend %cnt
  padding: 0 !important

  .inx-img-crop
    &__content
      padding: 0

    &__boundary
      margin: 0 auto
      +oh
      +por
      @extend %hover

  .ifi-profile
    color: $gray-lighter
    +dib
    +fsz(10em)
    +lh(1)
    left: 50%
    top: 50%
    +por
    transform: translate(-50%, -50%)

  .ifi-plus
    +fsz(3em)

  .btn
    &_img-add, &_img-edit
      @extend %elm

  .btn-group_img-edit
    +fl

  .info-txt
    display: none
    color: red
    padding-bottom: $grid-min-size
    text-align: center
    width: 100%
