//******************************************************************************
  * Adapt the new toolbar (header, footer) for portal needs
  ******************************************************************************
// Breakpoints reference:
  XS  (x<480px)
  MS  (480-719px)
  SM  (720-959px)
  MD  (960-1259px)
  LG  (1260-1439px)
  XL  (1440-1919px)
  XXL (x>1920px)

@include xs
  html, body
    +h(auto)

#header
  +h(auto)
  padding:
    bottom: $grid-gutter-width
    top: $grid-gutter-width
  // No border for "affix-ed" header
  &:not(.affix)
    border-bottom: 1px  solid $brand-gray

  //// Hide search, weather and nameday
  //.col-search, .col-weather, .col-nameday
  //  display: none !important

// Fullscreen pages
.inx-fs__body
  header
    border-bottom: 0
  // Hide login button on login page
  &.inx-page_login
    .nav-login
      // use visibility to reserve space - needed for language dropdown positioning
      visibility: hidden

#footer
  bottom: auto
  +pos
