//******************************************************************************
  * V2 of customized alerts
  ******************************************************************************
%ico-close-alert
  font-size: 1rem
  +poa
  top: 50%
  transform: translateY(-54%)

.inx-alert
  color: $text-color
  font-size: 1rem
  margin: 0 0 $grid-gutter-width 0
  p
    +nmp
  // Close button
  .ipi-close
    @extend %ico-close-alert
    right: calc($grid-gutter-width / 2)
    background: none
    border: none

  &_has-icon
    padding-left: 3rem !important
    +por
    &::before, &::after
      font-family: 'shared-icons'
      left: calc($grid-gutter-width / 2)
      @extend %ico-close-alert
      left: 20px

    @extend %alert-icons // defined in toolbarbundle


  &.alert-warning
    .close
      color: $alert-warning-text

  &.alert-danger
    // Alert link color
    a
      color: $white
      +tdu
    &::before, .close
      color: $alert-danger-text

  &.alert-info
    .close
      color: $alert-info-text

  &.alert-success
    .close
      color: $alert-success-text

.alert.alert_has-icon::before
  left: -($grid-gutter-width * 1.5)
  margin-right: -($grid-gutter-width * 1.5)


.alert-danger
  color: #fff
  background: $brand-danger

.alert-info
  color: #377385 !important
  background-color: $state-info-bg
