//******************************************************************************
// Text block scroller
// Used on:
//   + signup page
//   + /profile/privacy (forced)
//******************************************************************************
@import './params-only'

// Container state styles
.text-container
  &_default
    +h(40vh)
    +oh
    overflow-y: auto
  &_height-auto
    +h(auto)
    padding: 0
    > *
      padding-top: 0 !important
  &_scroller
    // border: 1px solid #9ea3b1
    +h(40vh)
    overflow-x: auto
    padding: 0 $grid-gutter-width
    +por
    +w(100%)


.form-group_scroller
  .buttons-pane
    margin-top: 1em
  .btn-wrap
    text-align: center

// Tweak for profile page
.buttons-pane
  #article_profile &
    margin-bottom: - $grid-gutter-width

// tippy.js customization
.privacy-tippy
  .tippy-box
    border-color: #777 !important
    +fsz(1.1em)
    .tippy-arrow
      &::after
        border-bottom-color: #777 !important
    .tippy-content
      display: flex
      align-items: center
      .ifi-sign-info
        color: #ffa300
        +fsz(1.4em)
        margin-right: .25em
