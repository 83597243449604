//******************************************************************************
  * Login block (inputs & buttons)
  ******************************************************************************

$login-link-color: #0175FF

html.login-eu
    background: #fafafa

#implogin
  margin: 3vw auto 0

.fullpage-body-container
  min-height: 100vh
  /* mobile viewport bug fix, alternatively use 100dvh */
  min-height: -webkit-fill-available
  display: flex
  flex-direction: column

//todo needed more general solution as this border radius can be used in more places in near future
.border-radius-2-and-a-half
  border-radius: 0.25rem !important

.inx-page_login
  .inx-login-form
    a.link
      color: $login-link-color

  input.is-invalid + .invalid-feedback
    display: inline-flex

  //hide show/hide password functionality built-in Edge browser
  ::-ms-reveal
    display: none

  .show-hide
    position: absolute
    top: 20px
    right: 20px
    font-size: 1.4rem
    z-index: 2
    cursor: pointer
  //if is-invalid hide the eye icon
  input.is-invalid ~ .show-hide
      display: none

.inx-login-fields
  align-content: center
  display: flex
  flex-direction: row
  justify-content: center
  &__inputs
    flex-grow: 1
    flex-basis: 0
    display: flex
    flex-direction: column
    justify-content: center

.btn-primary
  &.btn-red
    background-color: $brand-red
    border-color: $brand-red
    &:focus
      background-color: darken($brand-red, 10%)
      border-color: darken($brand-red, 25%)
    &:hover
      background-color: darken($brand-red, 10%)
      border-color: darken($brand-red, 12%)
    &:active
      &:hover,
      &:focus
        background-color: darken($brand-red, 17%)
        border-color: darken($brand-red, 25%)

.login-form-main-btn
  padding: 0.5rem 2.5rem
  font-weight: bolder
  white-space: nowrap

.bizmail-login
  .ico-euadmin-login
    display: inline-block
    width: 20px
    height: 20px
    background: url("../../img/euadmin-login.svg") no-repeat

@media (max-width: 719px)
  html
    background: none !important

@media (min-width: 720px)
  .inx-page_login
    .inx-login-form
      margin-top: 3vw
      padding: 3 * $grid-min-size
      background-color: rgba(#fff, 1)
      border: 1px solid $gray-icon
      box-shadow: 0 2px 20px 0 #0000000D
      max-width: 920px
      .max-half-for-desktop
        max-width: 50%

@media only screen and (max-width: 870px)
  .inx-page_login
    .inx-login-form
      padding: 2 * $grid-min-size

#header
  background: $white
