.card
  margin-bottom: 20px

.card-title
  +fade-icon
  font-size: 1rem

//bootstrap globally selects wrong text color for danger  elements black but we need white
//probably because some gradient magic
.text-bg-danger
  color: $white !important
  background: $brand-red !important


.card-logo
  width: 40px
  height: 25px
  margin: 0 10px 0 0
  display: block
  float: left
  &.visa
    background: url('../../img/card/visa.svg') no-repeat
    background-size: 40px 25px
  &.mastercard
    background: url('../../img/card/mastercard.svg') no-repeat
    background-size: 40px 25px
  &.amex
    background: url('../../img/card/amex.svg') no-repeat
    background-size: 40px 25px
  &.discover
    background: url('../../img/card/discover.svg') no-repeat
    background-size: 40px 25px
  &.unknown
    background: url('../../img/card/unknown.svg') no-repeat
    background-size: 40px 25px
  &.google
    background: url('../../img/card/google.svg') no-repeat
    background-size: 40px 25px
  &.apple
    background: url('../../img/card/apple.svg') no-repeat
    background-size: 40px 25px
