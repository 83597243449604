//******************************************************************************
  * Modifiers for pages that use one column layout (not logged in)
  ******************************************************************************
// Email verification status page
.eml-verify
  &__message
    padding: $grid-min-size 0 $grid-min-size 58px
    +por

  // Red text for failure
  &__message_failure
    color: #c00

  &__img
    +db
    +h(48px)
    +fl
    left: 0
    margin-top: -24px
    +poa
    top: 50%
    +w(48px)
