//******************************************************************************
  * User sign-up page
  ******************************************************************************

.inx-page_sign-up
  .card
    border: 1px solid $gray-icon
    border-radius: 0
    margin-top: 2 * $grid-gutter-width
  .card-heading
    border-color: $gray-icon

.signup-form
  &__fieldset
    align-content: center
    display: flex
    flex-direction: row
    justify-content: center
  &__fields
    flex-grow: 1
    flex-basis: 0
    .pb-2
      padding-bottom: 0.625em
  .social-accounts
    align-self: top
    height: (2 * $input-height-base) + (2 * $form-group-margin-bottom)

  .form-control_inx
    text-transform: lowercase
    padding-right: 25%
  .inx-domain-cnt
    +por
  .inx-domain-name
    +poa
    left: -100%
    top: 50%
    transform: translate(-50%, -50%)
    z-index: 3

  .signup-domain-select
    .dropdown-toggle
      padding: 0 12px
    .dropdown-menu
      background: $gray-lighter
      border: 1px solid rgb(158, 163, 177)
      min-width: 120px
      li
        a
          color: $text-color
          display: block
          padding: 3px 20px
          &:hover
            background: $text-color
            color: $gray-lighter
  .caret
    width: 0
    height: 0
    border-left: 5px solid transparent
    border-right: 5px solid transparent
    border-top: 5px solid #000
    display: block
    float: right
    margin-top: 8px
    margin-left: 8px
  #signup_reason
    padding: 0
    visibility: visible
    height: 0
    position: relative
    left: -99999px
@media (min-width: $screen-md)
  .modal-dialog
    max-width: $screen-ms

@media (min-width: $screen-xs) and (max-width: $screen-ms)
  .inx-page_sign-up .card
    margin-top: floor(calc($grid-gutter-width / 2))

  .signup-form .form-control_inx
    padding-right: 35%

@media (max-width: $screen-ms)
  .cf-turnstile
    transform: scale(0.9)
