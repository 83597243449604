//******************************************************************************
  * Site layout related
  ******************************************************************************
html, body
  +h(100vh)
  line-height: 1.4rem
body
  +bgc(white)
  // Sticky footer
  &:not(.inx-fs__body)
    display: flex
    flex-direction: column
    >.container
      flex: 1 0 auto

.content-wrap
  margin-top: $grid-gutter-width

  article
    padding-bottom: $grid-gutter-width
