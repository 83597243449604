//******************************************************************************
// Modals
//******************************************************************************

@import './params-only'

// "Special modal"
$border-width: 10px

.modal-header
  .btn-close
    position: absolute
    top: 15px
    right: 15px
    font-size: 0.5rem
    span
      display: none

.modal_marketing
  z-index: 10000 // iScroll scrollbar uses z-index: 9999
  .modal-header
    border: 0
    margin-bottom: 28px
    padding:
      bottom: 0
      top: 0

  .modal-title
    color: $brand-red
    font-weight: bold
    text-align: center
    //margin-top: 28px
    +por
    //border-top: $border-width solid $brand-red
    &::before
      border-top: $border-width solid $brand-red
      content: ''
      +db
      margin: 0 (2 * $grid-gutter-width)
      margin-bottom: 2 * $grid-gutter-width
    &::after
      border: $border-width solid transparent
      border-top-color: $brand-red
      content: ''
      +db
      +h($border-width)
      left: 50%
      +poa
      top: $border-width
      transform: translateX(-50%)
      +w($border-width)
  .modal-body
    border-top: 1px solid #e5e5e5
    padding:
      left: $grid-gutter-width
      right: $grid-gutter-width
    .lead
      font-size: 1.25rem

@media screen and (min-width: $screen-sm)
  .modal-dialog
    max-width: $screen-ms
