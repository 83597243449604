
//from core variables? todo those not needed to be added here as core variables are always imported before bootstrap
$brand-red:             #ce2127;
$gray-icon:             #9ea3b1;

$brand-primary:         $brand-dark;
$brand-success:         #5cb85c;
$brand-info:            #def2f8;
$brand-warning:         #f0ad4e;
$brand-danger:          #dc651e;

$blue:    $brand-primary !default; //#0d6efd
$red:     $brand-danger !default; //#dc3545
$yellow:  $brand-warning !default; //#ffc107
$green:   $brand-success !default; //#198754
$cyan:    $brand-info !default; //#0dcaf0

$link-color:            #2a6887 !default;
$link-hover-color:      $brand-red !default;

$btn-line-height:       1.5 !default;
//$grid-gutter-width:     0.7rem !default;
$container-padding-x:   0.7rem !default;
$border-radius-lg:      0 !default;

$font-weight-bolder:          bold !default;
$h4-font-size:                $font-size-base * 1.25 !default;
$h5-font-size:                $font-size-base * 1.15 !default;
$lead-font-size:              $font-size-base * 1.5 !default;

$input-padding-y:             .5rem !default; //$input-btn-padding-y !default;

$input-btn-padding-y-lg:      .375rem !default;

$input-border-color:                    $gray-icon !default;


$btn-border-radius:           0.125rem !default;
$btn-border-radius-lg:        0 !default;

$form-label-font-weight:                700 !default;

$input-focus-border-color:              $brand-dark !default;
$input-focus-box-shadow:                inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(85,107,124,.6) !default;
$form-check-min-height:                 1.6rem !default;

$form-select-border-radius:   .25rem !default; //$input-border-radius !default;

$modal-title-line-height:           1.4 !default;

$card-border-radius:               0 !default;
$card-title-spacer-y:               0 !default;
$card-cap-padding-y: 0.7rem;

$list-group-color:                  $link-color !default;
$list-group-active-color:           $link-color !default;

$alert-success-bg:            $state-success-bg !default;
$alert-success-text:          $state-success-text !default;
$alert-success-border:        $state-success-border !default;

$alert-info-bg:               $state-info-bg !default;
$alert-info-text:             $state-info-text !default;
$alert-info-border:           $state-info-border !default;

$alert-warning-bg:            $state-warning-bg !default;
$alert-warning-text:          $state-warning-text !default;
$alert-warning-border:        $state-warning-border !default;

$alert-danger-bg:             $brand-danger;
$alert-danger-text:           $white;
$alert-danger-border:         $state-danger-border !default;

//Override buttons from toolbar - in future should match toolbar style and ALL login button classes updated
$theme-colors: (
  "primary": $brand-dark,
  "default": #FFFFFF,
  "secondary": $brand-gray
);
