.billing
  .table
    margin: 0
    color: $dark-gray

    thead > tr
      background-color: #fafafa
      border: 0
      > th
        border: 0

    tbody > tr
      border-bottom: 1px solid #f1f1f1
      &:last-child
        border: 0
      > td
        border: 0
        padding: 10px 12px
        font-weight: 300
        vertical-align: top
    tbody.card-table > tr
      &:first-of-type
        > td
          padding-top: 0
      &:last-of-type
        > td
          padding-bottom: 0
      > td
        padding: 6px 0

    .extra-small-cell
      width: 60px
    .small-cell
      width: 125px
    .large-cell
      width: 200px

    @media (max-width: $screen-sm-max)
      .tablet-hidden-cell
        display: none

    a:not(.btn)
      color: $link-color
      text-decoration: none

  .card-body
    .btn-subscribe
      margin-bottom: 10px
      margin-top: 0
      max-width: 240px

  .card-footer
    background: $white
    border: 0

    a:not(.btn)
      color: $link-color

  .card-body
    .btn
      margin: 11px 0 0 0

  .dropdown-menu
    padding: 5px
    background: $white
    .btn
      width: 100%

    .dropdown-item
      padding: 10px 15px
      display: block
      border: 1px solid $gray-lighter
      &:hover
        background: $white

  .card-info
    float: right
    .card-number
      font-size: 16px
    .card-expiry
      margin-top: 0
      font-size: 14px
      display: block

  .payment-methods
    .card-number
      font-size: 15px
      color: black
      font-weight: bold
    .card-expiry
      color: darkgray
      font-size: 15px
      font-weight: normal
    .card-logo
      vertical-align: center
      background-size: 34px 25px
    .card-default
      font-size: 12px
      color: darkgray

  .card-logo
    margin-top: 14px
    display: inline-block
    float: inherit

  .subscription-data
    margin: 0 0 1px 0

  .product-name
    font-weight: bold
    font-size: 16px

  .product-cancel
    font-size: 14px

  .product-date
    font-size: 14px
    font-weight: normal
    color: #a8a8a8

  .renew-billing
    margin-top: 8px

  .ifi-ellipsis
    color: gray
    font-size: 20px

