//******************************************************************************
  * Full screen login page
  ******************************************************************************
@import '../blocks/social-accounts'
@import './login'
@import './sign-up'

.inx-fs
  &__html, &__body
    +h(100%)
    +w(100%)

  &__html
    background:
      position: center
      size: cover

  &__body
    background-color: transparent

    .inx-header
      +bgc(rgba(#fff, 0.7))
      +clearfix
      &__row
        //&_branding
        //  +make-sm-column(11)
        //  +make-md-column(11)
        //  +make-lg-column(10)
        &_products
          display: none !important

    .btn-toolbar
      &_inx-header
        .btn-group:not(:last-child)
          display: none !important

    .inx-lang-sel
      &.ui-state-hover, &.ui-state-focus
        border-color: transparent

.mail-notification
  background-color: $brand-orange
  color: $white
  padding: 15px 20px
  position: relative
  margin-bottom: 2vw
  font-size: 15px
  font-family: ProximaNova, Arial, sans-serif
  span
    font-size: ($font-size-base + 4)
    font-weight: 300
    line-height: 14px
  .ipi-alert-warning
    position: absolute
  .ipi-alert-danger
    position: absolute
  .close
    position: absolute
    right: 20px
    top: 15px
    color: $white
    font-size: ($font-size-base + 10)
    height: 24px
    outline: none
    opacity: $opacity-default
    &:hover
      opacity: $opacity-on-hover
  p
    padding: 3px 35px 0px 21px
    font-weight: 100
    line-height: 14px
    strong
      font-size: 18px
    a
      color: $white
      text-decoration: underline

#background-video
  position: fixed
  top: 50%
  left: 50%
  min-width: 100%
  min-height: 100%
  width: auto
  height: auto
  z-index: -100
  -ms-transform: translateX(-50%) translateY(-50%)
  -moz-transform: translateX(-50%) translateY(-50%)
  -webkit-transform: translateX(-50%) translateY(-50%)
  transform: translateX(-50%) translateY(-50%)
  background-size: cover

#background-video-title
  position: absolute
  bottom: 7vh
  font-size: 8vh
  left: 3vw
  color: #eeeeee
  text-shadow: 0px 0px 20px #000000

@media (max-width: $screen-sm-max)
  .inx-fs
    &__body
      .inx-header
        +bgc(rgba($gray-lighter, 0.7))
